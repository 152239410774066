import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ClimbingBoxLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import { authStore } from 'stores/AuthStore';

const MagicLinkPage = () => {
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const handleEmailLink = async () => {
      const email = window.localStorage.getItem('emailForSignIn');
      const url = window.location.href;

      if (!email) {
        setError('Email not found. Please try signing in again.');
        toast.error('Email not found. Please try signing in again.');
        return;
      }

      try {
        const success = await authStore.signInWithEmailLink(url);
        if (!success) {
          setError('Invalid or expired magic link');
          toast.error('Invalid or expired magic link');
          return;
        }
        console.log('success', success);
        window.localStorage.removeItem('emailForSignIn');
        
        // Check for community data in localStorage
        const communityData = localStorage.getItem('communityData');
        console.log('communityData', communityData);
        // attach community id to the use
        if (authStore.currentUser?.communityUsers && authStore.currentUser?.profile?.hasCompletedOnboarding) {
          const community_id = authStore.currentUser?.communityUsers[0]?.community?.id;
          if (community_id) {
            navigate(`/community/${community_id}`);
            return;
          }
        }

        console.log('go to home');
        // Otherwise, navigate to the default redirect path
        navigate('/');

      } catch (error) {
        console.error('Error signing in with email link:', error);
        setError('Failed to sign in. Please try again.');
        toast.error('Failed to sign in. Please try again.');
      }
    };

    handleEmailLink();
  }, [navigate]);

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <div className="text-center">
          <h2 className="text-xl font-semibold text-gray-900 mb-2">Sign In Failed</h2>
          <p className="text-gray-600">{error}</p>
          <button
            onClick={() => navigate('/login')}
            className="mt-4 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
          >
            Back to Login
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50">
      <div className="text-center">
        <ClimbingBoxLoader color="#2563eb" />
        <p className="mt-4 text-gray-600">Signing you in...</p>
      </div>
    </div>
  );
};

export default observer(MagicLinkPage);
